div.dialog
{
    width: 720px;
    margin: 50px auto;
    background-color: #fafafa;
    border: #ccc 1px dotted;
    padding: 60px;
    overflow: hidden;
    position: relative;
    
    font-size: 35px;
    color: #b8b8b8;
    text-shadow: 0 1px 0 #fff;
    font-weight: lighter;
    text-align: left;

    min-height:400px;
    position:relative;

    margin-top: 0px;
}



div.dialog .actions
{
    position:absolute;
    text-align: right;
    right: 50px;
    bottom: 50px;
}


div.dialog .form
{
    margin-top:20px;
}

div.dialog .form
{
    text-align:center;
}

div.dialog .form input[type=text],
div.dialog .form input[type=password]
.modal-body input[type=text],
.modal-body input[type=password],
.modal-body input[type=email],
.modal-body input[type=number],
.modal-body input[type=url],
.modal-body textarea
{
    padding: 19px;
    border: solid 1px #E5E5E5;
    outline: 0;
    font: normal 18px/100% 'Open Sans', Tahoma, sans-serif;
    background: linear-gradient(to bottom, #ffffff 0%,#eeeeee 1px,#ffffff 25px);
    box-shadow: rgba(0,0,0, 0.1) 0px 0px 8px;

    margin-top: 30px;
    margin-bottom: 0px;
    width: 400px;
    padding-bottom: 15px;

}

.modal-body textarea
{
    width: 100%;
}

div.dialog .form input[type=checkbox]
.modal-body input[type=checkbox]
{
    width:20px;
    height:20px;

    display: inline-block;
    margin-bottom: -20px;
    position: absolute;
    left: -35px;
    top: -5px;

}

div.dialog .form label
{
    font-size:15px;
    position: relative;

}

div.dialog .form input[type="checkbox"] + label {
    margin-left:10px;
}

.ui-dialog-titlebar
{
    display:none;
}

.ui-widget-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: .3;
    z-index:101;
}

.ui-dialog
{
    z-index:102;
    outline: none;

}

div.dialog .extra-text
{
    
    padding:50px;
    font-size:18px;
    padding-left:0px;
    white-space: pre-wrap;
    line-height:30px;
}

div.dialog .explanation
{
    font-size: 15px;
    margin-top: 20px;
    margin-bottom: -20px;
    margin-right: -5px;

    white-space: pre-wrap;
    line-height: 20px;
}

div.dialog .show-pass
{
    margin: 150px 0px;
}

div.dialog a
{
    text-decoration: none;
}
