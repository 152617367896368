
.website-box .stats-toolbox .icon-text-link
{
    float:right;
    display:none;
}

.website-box:hover .stats-toolbox .icon-text-link
{
    display:block;
}

.website-box .stats-toolbox .icon-text-link a
{
    color: #bbb;
}

.website-box .stats-toolbox .icon-text-link a:hover {
    color: rgb(102, 102, 102);
    cursor: pointer;
}

.website-box .stats-toolbox .icon-text-link.notes,
.website-box .stats-toolbox .icon-text-link.delete,
.website-box .stats-toolbox .icon-text-link.edit,
.website-box .stats-toolbox .icon-text-link.fav{
    float: left;
}



.website-box .stats-toolbox .icon-text-link.view-ads,
.website-box .stats-toolbox .icon-text-link.refresh {
    display: block;
}

.website-box .stats-toolbox .icon-text-link.warning,
.website-box:hover>div>div:not(.custom) .stats-toolbox div>div:not(.has-account) .edit.warning

{
    display: inherit;
    
}

.website-box .stats-toolbox .icon-text-link.warning a
{
       color:red !important;
}


.website-box .stats-toolbox a
{
    font-size:20px;
    margin: 5px;
}

.website-box .stats-toolbox .notes,
.website-box .stats-toolbox .edit 
{
    margin-top:-1px;
    
}

.website-box .stats-toolbox .notes a i

{
    
    font-size:15px;
}

.website-box .stats-toolbox .edit a i
{
    font-size:18px;
}

.website-box .stats-toolbox .fav.active a i:before
{
    content: "\f004"
}






.website-box .stats-toolbox .icon-text-link.share>div:not(.enabled)
{
    display:none;
}