@import '~bootstrap/scss/bootstrap';
@import './components/index';

    @import './styles/dialog.css';
    @import './styles/layout.css';
    @import './styles/main.css';
    @import './styles/menu.css';
    @import './styles/stats-toolbox.css';
    @import './styles/toolbar.scss';
    @import './styles/website-box.scss';

    a {
        text-decoration: none;
    }

    .max,
    [data-class*="dialog"]
    {
        *, *::before, *::after
        {
            box-sizing: initial !important;
            
        }

        a,
        .main-header
        {
            line-height: 1;
        }

        *
        {
            text-align: center;
        }

        
    }


    .card form
    {
        padding: 20px 20px 20px 20px;
    }


    body
    {
        .global-header-menu-items
        {
            top:5px;
        }
        .has-alert
        {
            .global-header-menu-items
            {
                top: 48px;
            }
            .app-header{
                top: 48px;
            }   
        }
    }

    .global-header-menu-items
    {
        position: fixed;
        top: 30px;
        right: 5px;
        z-index: 100;

        .dropdown-header
        {
            font-weight: bolder;
        }

        a,
        button{
            color: #999;
            padding-left: 40px;
        }

        i
        {
            position: absolute;
            left: 15px;
            margin-top:3px;
            
        }

        .active
        {
            background:#3498db;
            color: white !important;

            &:hover
            {
                color: initial !important;
            }
        }

        
        
    }

    input[type=text].large,
    input[type=url].large
    {
        padding: 19px;
        border: solid 1px #E5E5E5;
        outline: 0;
        font: normal 18px/100% 'Open Sans', Tahoma, sans-serif;
        background: linear-gradient(to bottom, #ffffff 0%,#eeeeee 1px,#ffffff 25px);
        box-shadow: rgba(0,0,0, 0.1) 0px 0px 8px;
        margin-bottom:20px;
        width:100%;
    }

    input[type=text].xl,
    input[type=url].xl
    {
        padding: 19px;
        border: solid 1px #E5E5E5;
        outline: 0;
        font: normal 30px/100% 'Open Sans', Tahoma, sans-serif;
        background: linear-gradient(to bottom, #ffffff 0%,#eeeeee 1px,#ffffff 25px);
        box-shadow: rgba(0,0,0, 0.1) 0px 0px 8px;
        margin-bottom:0px;
        width:100%;

        &::placeholder{
            color: #999;
            font-weight: lighter;
        }
    }

    .overlay-spinner
        {
            

            &::before
            {
                content: '';
                position: absolute;
                width: calc(100% - 10px);
                height: calc(100% - 10px);
                left: 5px;
                background-color: white;
                opacity: 0.7;
                z-index: 1;
            }

            .spinner-border
            {
                position: absolute;
                top: calc(50% - 1rem);
                left: calc(50% - 1rem);
                z-index: 2;
            }
        }


    .list-group-item
    {
        .list-group-item-heading a
        {
            color:$globalFontColor;
            font-weight: bold;
        }
        img
        {
            float: left;
            margin-right: 20px;
            width: 240px;
            height: 180px;
        }

        .pull-right
        {
            position: absolute;
            bottom: 20px;
            right: 20px;
        }
    }

    .jumbotron
    {
        background-color: white;
        text-align: center;

        h1,
        p.lead
        {
            text-transform: uppercase;
        }

        .step::after
        {
            font: normal normal normal 14px/1 FontAwesome;
            content: "\f061";
            position: absolute;
            right: 0px;
            top:25px;
            font-size: 30px;
        }
    }

    input[type=radio] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        display: inline-block;
        width: 25px;
        height: 25px;
        padding: 6px;
        background-clip: content-box;
        border: 1.5px solid #bbbbbb;
        border-radius: 6px;
        background-color: #e7e6e7;
        margin-left: 15px;
        margin-right: 15px;
    
        &:checked{
            background-color:#127cd8;
        }
    
        &:focus{
            outline: none !important;
        }
    }