﻿.website-box
{
    width: 280px;
    height: 256px;
    border: 1px solid transparent;
    margin: 0 20px 18px 0;
    float: left;
    position: relative;

    .offer-description
    {
        font-size: 13px;
        text-align: left;
        padding: 0px 20px;
        line-height: normal;
        max-height: 55px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-top: 20px;
    }
}

.card-body
{
    .website-box
    {
        float: none;
        margin: auto;
    }
}

.website-box.details
{
    border-radius: 2px;
    box-shadow: 0 2px 2px rgba(0,0,0,0.15);
    text-align: center;
}

.website-box.details.selected
{
    border: 1px solid blue;
}


.website-box .custom .login-link
{
    display:none;
}

.website-box .title-link
{
    font-size:18px;    
    float: left;
    margin:15px;
    margin-left:20px;
    text-decoration: none;
    color:rgb(46, 46, 46);
    font-weight: bold;
    line-height: 1;

    &.offer-name
    {
        font-size: 16px;
        text-align: left;
        line-height: normal;
        max-height: 22px;
        overflow: hidden;

    }
}

.website-box .footer button
{
    width: 35%;
    margin: 5px;
    border: none;
    display: inline-block;
}

    .website-box div.edit-info{
        color: rgb(46, 46, 46);
        line-height: 16px;
    }

        .website-box div.edit-info div
        {
            padding: 20px;
        }

        .website-box div.edit-info span[contenteditable]
        {
            padding: 2px;
            font-weight: bold;
        }

        .website-box > div {
            background: #fff no-repeat center;
        }


.website-box .title-link:hover
{
    text-decoration: underline;
}

.website-box div.loading
{
    background-color: rgba(255, 255, 255, 0.8);

    padding-top: 100px;
    color: #aaa;
    font-size: 80px;
    z-index: 100;
}

.website-box .stats div.loading
{     
    background-image: none;
}


.ads:empty
{
    min-height:40px;
    min-height: 32px;
    text-align:center;   
}

.website-box.add-new
{
    background-color: transparent;
    border: 2px dashed lightgray;
     
        width: 276px;
    height: 253px;
    font-weight: normal;
    font-size: 30px;
    vertical-align:middle;
    text-align: center;

    text-shadow: 0 1px 0 #fff;
}

.website-box.add-new a
{
     padding-top: 100px;
     text-decoration:none;
     color:lightgray !important;
     outline: 0;
     float: left;
     width: 100%;
     height: 158px;

}

.website-box.add-new i
{
    display:block;
    margin-bottom:20px;
    font-size:50px;
}

.website-box div.heading
{
    height:48px;
    

    .extra-menu
    {
        position: absolute;
        right: 0px;
        top: 5px;
        height: 20px;
        font-size: 20px;

        a
        {
            color: #999;

            &:hover
            {
                color: rgb(102, 102, 102);
                cursor: pointer;
            }
        }
        
        .dropdown-item
        {
            &:not(.disabled)
            {
                width: 75%;
                color: #2e2e2e;
                text-shadow: 0 1px 0 #fff;
            }
            
            i{
                width:20px;
                height: 20px;
            }
        }
        
        .dropdown-menu
        {
            border-color: #0c90b1;
        }
    }
}

.website-box div.middle
{
    height:155px;
    color: white;
    padding-top:15px;
}

.website-box div.middle.login
{
    height:170px;
    padding-top: 0px;
}

.website-box div.footer.login
{
    line-height:30px;
}

.website-box div.stats.middle
{
    background-color: #0c90b1;
    font-size: 50px;
    line-height: 50px;
}


.website-box.custom div.stats.middle {
    background-color: #85C7D8;

}

.website-box.custom.premium-only div.stats.middle .refs
{
    display: none;
}

.website-box.custom.premium-only div.stats.middle .balance
{
    font-size: 30px;
    padding-left: 0px;
}

.website-box div.stats.middle .balance
{
    margin-top:5px;
    margin-bottom: 5px;
    padding-left: 25px;
}

.website-box .balance>div:before
{
   
    font-size: 40px;
    position:absolute;
    margin-left:-25px;
    margin-top:3px;
}


.website-box div.stats.middle .balance, .website-box div.stats.middle .refs
{
    color:#54B1C8;
}

.website-box.custom div.stats.middle .balance, .website-box.custom div.stats.middle .refs
{
    color:white;
}

.website-box div.stats.middle.fresh .balance, .website-box div.stats.middle.fresh .refs
{
    color:white;
}

.website-box div.stats.middle .label
{
    font-size:18px;
    margin-left:5px;
    
}


.website-box div.stats.middle .login-link a:nth-child(2):before {
    content: "|";
    margin-right: 10px;
}

.website-box div.stats.middle .login-link a {
    color: white;
    padding: 0px 5px;
}

.website-box div.stats.middle .login-link a:hover
{
    color:white;
}

.website-box div.stats.middle .refs, .website-box div.stats.middle .ads
{
    font-size: 30px;
    line-height: 35px;
    height: 40px;
}

.website-box div.stats.middle .no-data
{
    font-size: 18px;
    color: lightgray;
}

.website-box div.footer
{
    vertical-align: bottom;
    line-height: 40px;
    height:38px;
    padding-left:5px;
    padding-right:5px;
    color: #999;
}

.website-box div.footer .login-link
{

    float:left;
}

.website-box div.footer .icon-text-link.back
{
    font-size:25px;
    opacity:0.5;
}

.website-box div.footer .login-submit-link a
{
    margin-left: -15px;
    margin-top: 0px;
    width:140px;
}

.login-form input
{
    padding: 5px;
    border: solid 1px #E5E5E5;
    outline: 0;
    font: normal 13px/100% Verdana, Tahoma, sans-serif;
    box-shadow: rgba(0,0,0, 0.1) 0px 0px 8px;
    min-width: 168px;
}

.login-form .save-result
{
   height: 20px;
    padding-top: 10px;
}

.login-form input:hover
{
    border-color: #C9C9C9; 
	box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 8px;

}

.login-form-container
{
    display:inline-flex;
    height: 170px;
    overflow-y: auto;
}

.login-form
{
    margin: auto;
     max-height: 170px;
}

.login-form div.pass
{
    position:relative;
}

.login-form div.pass div.show-pass
{
    position: absolute;
    right: 8px;
    top: 8px;
}



.login-form div.pass-disabled
{
    color: #999;
    margin-top: 10px;
}

.captcha-tooltip-link
{
    color:#999;
    display:inline-block;
    margin-top:10px;
}

.captcha-tooltip-link:hover
{
    text-decoration:underline;
}

.expand-login-info
{
    float: right;
    margin-top: 17px;
    margin-right:14px;
}

.login-error
{
    color:red;
    line-height: 20px;
}

.showSlideDown
{
    -webkit-animation: showSlideDown 0.5s; 
    -moz-animation: showSlideDown 0.5s; 
}

.fade-in 
{
     -webkit-animation: showSlideUp 0.5s;
     -moz-animation: showSlideUp 0.5s;
}

 
/* Safari and Chrome */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


/* Safari and Chrome */
@-webkit-keyframes showSlideDown {
    from { margin-top: -25px; }
    to   { margin-top: 0px; }
}

@-webkit-keyframes showSlideUp {
    from { margin-top: 15px; }
    to   { margin-top: 0px; }
}

@-webkit-keyframes sectionSelected {
    from { max-height: 20px; }
    to   { max-height: 1000px; }
}

@-webkit-keyframes sectionUnselected {
    from { max-height: 1000px; }
    to   { max-height: 20px; }
}