.valign-outer {
    display: table;
    position: absolute;
    height: 100%;
    width: 100%;
}

.valign-middle {
    display: table-cell;
    vertical-align: middle;
}

.valign-inner {
    margin-left: auto;
    margin-right: auto; 
}

.icon-text-link a:hover
{
    color:rgb(102, 102, 102);
}

.icon-text-link a
{
        text-decoration:none;
    font-weight: normal;
    color: #999;
    outline:none;
}

.icon-text-link a:hover
{
    text-decoration:underline;
    color:rgb(102, 102, 102);
}



@-webkit-keyframes shake {   
   0% { margin-left:-10px;}
   10% { margin-left:10px;}
   20% { margin-left:-7px;}
   30% { margin-left:7px;}
   40% { margin-left:-3px;}
   50% { margin-left:3px;}
   60% { margin-left:inherit;}
}

@-moz-keyframes shake {   
   0% { margin-left:-10px;}
   10% { margin-left:10px;}
   20% { margin-left:-7px;}
   30% { margin-left:7px;}
   40% { margin-left:-3px;}
   50% { margin-left:3px;}
   60% { margin-left:inherit;}
}


@-webkit-keyframes showmsg {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        display:none;
    }
}

.shake {
   -webkit-animation: shake 1s ease-in 1;
   -moz-animation: shake 1s ease-in 1;

}

.showmsg {
    color:blue;
     -webkit-animation: showmsg 3s ease-in 1;
     -moz-animation: showmsg 3s ease-in 1;
}

.showerr {
    color:red;
     -webkit-animation: showmsg 3s ease-in 1;
      -moz-animation: showmsg 3s ease-in 1;
         
}

.err {
    color:red;
         
}

.max.loading
{
    background-color: rgba(255,255,255, 0.5);
    background-image: url('http://cdnjs.cloudflare.com/ajax/libs/semantic-ui/0.13.0/images/loader-large.gif');
    background-repeat: no-repeat;
    background-position: center;

}

.section-title
{
    font-size: 35px;
    color: #b8b8b8;
    text-shadow: 0 1px 0 #fff;
    font-weight: lighter;
    margin-bottom:10px;
    text-align:center;
}

.center-message
{
    color:#b8b8b8;
    font-size:35px;
    font-weight:normal;

}

iframe
{
    border:none;
}