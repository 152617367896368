.mfa-dialog {
    img {
        width: 200px;
        height: auto;
        border: solid 1px #E5E5E5;
    }
    p {
        text-align: left;
    }
    
    input {
        display: block  ;
        margin: auto;
        max-width:  200px;
        box-shadow: none !important;
        margin-top: 10px !important;
        background: white !important;  
    }

    strong {
        margin-top: 10px;
        text-align: center;
        display: block;
    }
}