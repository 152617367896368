/* body {
    color: #2e2e2e;
    font-family: Open Sans,Arial,sans-serif;
    font-size: 13px;
    background: #f1f1f1 !important;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    padding: 0;
    height: 100%; 
    min-height: 100%;
    overflow-x: hidden;
} */


body a {
    cursor: pointer;
}

.scrollable
{
    overflow-y:scroll;
    overflow-x: hidden;
}






.usd:before,
.btc:before,
.rub:before,
.satoshi::before,
.mbtc::before
{
     font-family: FontAwesome;
}

.usd:before
{
    content: "\f155";
}

.rub:before
{
    content: "\f158";
}

.satoshi::before,
.btc:before,
.mbtc:before
{
    content: "\f15a";
}

@keyframes hideAfter {
    to {

        visibility:hidden;
    }
}

@keyframes showAfter {
    to {

        visibility:visible;
    }
}



nav.main-header
{
position: fixed;
left:0px;
top: 0px;

width: 100%;
height: 60px;
text-align: center;
background: #f5f5f5;
overflow: visible;


z-index: 0;
box-shadow: 0px 0 5px -5px #CCC, 0 5px 5px -5px #CCC, -5px 0 0px -5px #CCC;


}

nav.main-header.main-title,
nav.main-header.totals
{
    width: auto;
    margin: 0 0 0 -100px;
    z-index:100;
    display: inline-block;
    box-shadow: none;
}

  


nav.main-header.premium-offer
{
    right: 100px;
    width: 300px;
    z-index: 100;
    font-size: 16px;
}

nav.main-header.premium-offer>div
{
    padding-top: 25px;
}
    

nav.main-header .menu
{
    position: relative;
    display: inline-block;
}

nav.main-header .title
{
   font-size: 30px;
    font-family: Open Sans,Arial,sans-serif;
     color: #999;
         display: inline-block;
 padding-top: 15px;
    position: relative;
         text-shadow: 0 1px 0 #fff;
         padding-left:240px;
}

nav.main-header a.title
{

    background-position-x:right;
    background-position-y: center;
    background-repeat: no-repeat;
    padding:20px;
    cursor: pointer;
     text-shadow: 0 1px 0 #fff;
    
}


nav.main-header .menu a.title
{
    padding:0px;
    padding-top:15px;
}


nav.main-header a.title:hover
{
    color:#343434;
}


.totals.balance > span:not(:last-child):after
{
    content:" + ";
}

.totals.balance > span
{
    position: relative;
    padding-right: 20px;
}
.totals.balance > span::before
{
    font-weight: normal;
    font-size: 20px;
    position: absolute;
    left: -15px;
    top: 8px;
    width: 15px;
}

nav.main-header .totals.refs
{
    font-weight:normal;
    font-size:17px;

}




#ContentPanel
{
    top: 64px;
    margin-left:240px;
     -webkit-animation: showContentPanel 0.5s, fadein 0.5s;
     -moz-animation: showContentPanel 0.5s, fadein 0.5s; 
}


.slide-away
{
     -webkit-animation: slideAway 0.5s;
      -moz-animation: slideAway 0.5s;
}

.slide-in
{
     -webkit-animation: slideIn 0.5s;
     -moz-animation: slideIn 0.5s;
}

@-webkit-keyframes showContentPanel {
    from { margin-top: 500px; }
    to   { margin-top: 0px; }
}


@-moz-keyframes showContentPanel {
    from { margin-top: 500px; }
    to   { margin-top: 0px; }
}


@-moz-keyframes showHeader {
    from { margin-top: -60px; }
    to   { margin-top: 0px; }
}


@-webkit-keyframes showHeader {
    from { margin-top: -60px; }
    to   { margin-top: 0px; }
}

@-moz-keyframes slideAway {
    from { right: 0%; }
    to   { left: -100%; }
}

@-webkit-keyframes slideAway {
    from { right: 0%; }
    to   { left: -100%; }
}

@-moz-keyframes slideIn {
    from { left: 100%; }
    to   { left: 0%; }
}

@-webkit-keyframes slideIn {
    from { left: 100%; }
    to   { left: 0%; }
}


div.max
{
    bottom: 0px;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
}




        /**** Isotope Filtering ****/

        .isotope-item {
            z-index: 2;
        }

        .isotope-hidden.isotope-item {
            pointer-events: none;
            z-index: 1;
        }

        /**** Isotope CSS3 transitions ****/

        .isotope,
        .isotope .isotope-item {
            -webkit-transition-duration: 0.8s;
            -moz-transition-duration: 0.8s;

        }

        .isotope {
            -webkit-transition-property: height, width;
            -moz-transition-property: height, width;
        }

            .isotope .isotope-item {
                -webkit-transition-property: -webkit-transform, opacity;
                -moz-transition-property: -moz-transform, opacity;
            }


.websites-dashboard
{
    margin: 30px;
    text-align:left !important;
    perspective:500px;

    
}

.dashboard *
{
    box-sizing: content-box !important;
}

     
.account-iframe
{
    width:100%;
    height:99%;
    border:0;
}



.no-focus-outline *:focus
{
    outline: 0;
}

img:-moz-loading {
        visibility: hidden;
}

.avatar
{
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.dialog.start-error {
  color: red;
  text-align: center;
  min-height:0px;
}

.dialog.start-error .extra {
  margin: 20px;
  font-size: 20px;
}