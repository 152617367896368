
.account-status
{
    color: white;
    font-weight: normal;
    position: absolute;
    bottom: 40px;
    width: 100%;
}

.account-status button
{
    text-transform: uppercase;  
}

.account-status a.why-upgrade
{
    color:#999;
    border-bottom:none;
    margin-top: 10px;
    display: block;

}

.account-status a.why-upgrade:hover
{
    text-decoration:underline;
    background-color: inherit;
    color:white;
}


.account-status > div:first-child
{
    text-transform: uppercase;
    margin: 10px 0px;
    font-size: 20px;
    white-space: nowrap;
}

.account-status >div.expires
{
    color: #777;
    font-weight: bold;
    margin: 5px;
    white-space: nowrap;
}
