﻿.toolbar
{
    position: fixed;
    bottom: 0px;
    width: calc(100% - #{$sidebarWidth}) ;
    height: 40px;
    right: 0px;
    text-align: center;
    background: #1d1f24;
    z-index: 1000;
    color: white;
    padding-top: 2px;
    margin-bottom: -65px;
    transition: margin-bottom 0.5s;

    &.offers-playlist
    {
        height: 60px;

        .total
        {
            font-size: 35px;
            position: absolute;
            right: 140px;
        }

        .toolbar-actions
        {
            a{
                position: absolute;
                right: 0px;
                top: 0px;
                width: 100px;
                height: 60px;
                i{
                    width: 50px;
                    height: 50px;
                    border: 3px solid white;
                    border-radius: 50%;
                    margin: auto;
                    padding-left: 7px;
                    padding-top: 4px;
                    margin-top: 2px;

                    &::before
                    {
                        font-size: 35px;
                    }
                }
            }

            &:before
            {
                width:1px;
                height: 100%;
                border: 2px solid white;
                content: '';
                
                bottom: 0px;
                position: absolute;
                right: 100px;

            }
        }
    }
}

.toolbar.active
{
    margin-bottom: 0px;
    
}


.toolbar-hint
{
    height: 100%;
line-height: 25px;
white-space: pre-wrap;
padding-top: 5px;
font-weight: normal;

}

.toolbar-actions
{
    
}

.toolbar-actions>div
{
    display:inline-block;
    height:50px;
    
}
.toolbar-actions>div i
{
    font-size:30px; 
    display:block;
}

.toolbar-actions a
{
    color:white;
    display:inline-block;
    min-height:60px;
    padding-top:4px;
}

.toolbar-actions>div:hover a.enabled
{
        background-color:#258ecd;
}

.toolbar-actions a:hover 
{
    text-decoration:none;

    color:white;
}

.toolbar-actions a:not(.enabled)
{
    cursor:default;
    
}

.toolbar-actions a:not(.enabled):hover
{
    color:inherit;
}

.toolbar-actions a:not(.enabled)>*
{
    opacity:0.5;
}

.toolbar-actions>div a>span
{
    width:70px;
    float:left;
    font-size:12px;
}

